import React from "react";
import PropTypes from "prop-types";

const Section = ({ bgColor, dark, heading, children, subheading, className }) => {
  return (
    <section className={`section ${className} ${dark ? 'dark' : 'light'}`} style={{ backgroundColor: bgColor }}>
      <h2>{heading}</h2>
      {subheading && <h5>{subheading}</h5>}
      <div className="content">{children}</div>
    </section>
  );
};

Section.propTypes = {
  bgColor: PropTypes.string,
  heading: PropTypes.node.isRequired,
  subheading: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  bgColor: "transparent",
  dark: false,
  className: ""
};

export default Section;
